
.button {
    width: 218px;
    height: 42px;
    background-color: #FF1654;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    letter-spacing: 2px;

    .text {
        cursor: pointer;
        font-family: 'Avenir';
        font-size: 12px;
        font-weight: bold;
    }

}